/* storybook-check-ignore */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import { Button, Divider, Flex, Icon, List, ListItem } from '@opendoor/bricks/core';
import { useOutsideClickAlerter } from '@opendoor/bricks/hooks/useOutsideClickAlerter';
import { useRouter } from 'next/router';

import { HeaderMenuItem } from 'components/exclusives/layout/Headerv2/HeaderMenu/HeaderMenuItem';
import { HeaderNavigationItem } from 'components/exclusives/layout/Headerv2/HeaderNavigation/HeaderNavigationItem';
import LoggedInConnector, { AccountProps } from 'components/shared/AccountConnector';

import { useExclusivesTypeContext } from 'helpers/exclusives/ExclusivesTypeContext';
import { useObservability } from 'helpers/observability';
import { Lead, useLeads } from 'helpers/useLeads';

export type HeaderMenuOption =
  | (
      | {
          analyticsName: string;
          label: string;
          href: string;
          icon?: ReactNode;
          type: 'item';
        }
      | {
          type: 'divider';
        }
      | {
          analyticsName: string;
          label: string;
          onClick: () => void;
          icon?: ReactNode;
          type: 'button';
        }
    ) & {
      isMobileOnly?: boolean;
    };

export type HeaderMenuProps = Pick<AccountProps, 'loggedIn' | 'maybeLogOut'>;

export const HeaderMenu = ({ loggedIn: isLoggedIn, maybeLogOut: onLogOut }: HeaderMenuProps) => {
  const router = useRouter();
  const [isOpened, setIsOpened] = useState(false);
  const { type } = useExclusivesTypeContext();
  const { trackEvent } = useObservability();
  const menuRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const leads = useLeads();

  const isExclusives = type === 'exclusives';
  const telephone = isExclusives ? '512-706-9938' : '1-888-352-7075';
  const analyticsPrefix = `cosmos-${type}-header-menu`;

  /**
   * Closes the menu when the user clicks anywhere
   * outside of the menu.
   */
  useOutsideClickAlerter(menuRef, () => setIsOpened(false));

  const menuItems = useMemo<HeaderMenuOption[]>(() => {
    const items: HeaderMenuOption[] = [];

    if (leads?.length > 0) {
      leads.slice(0, 3).forEach((lead: Lead) => {
        items.push({
          analyticsName: `${analyticsPrefix}-lead-${lead.id}`,
          href: lead.url,
          label: `${lead.address.street as string}${
            lead.readable_offer_state ? ` (${lead.readable_offer_state})` : ''
          }`,
          type: 'item',
        });
      });
      items.push({
        type: 'divider',
      });
    }

    if (leads && leads.length > 3) {
      items.push({
        analyticsName: `${analyticsPrefix}-lead-more`,
        href: '/properties',
        label: 'More Properties',
        type: 'item',
      });
      items.push({
        type: 'divider',
      });
    }

    if (isLoggedIn) {
      items.push(
        {
          analyticsName: `${analyticsPrefix}-preferences`,
          href: '/exclusives/preferences',
          icon: (
            <Icon
              color="currentColor"
              name="filter"
              // @ts-expect-error - This is a known deviation from the design system.
              size={20}
            />
          ),
          isMobileOnly: true,
          label: 'Preferences',
          type: 'item',
        },
        {
          analyticsName: `${analyticsPrefix}-saved-homes`,
          href: '/exclusives/saved-homes',
          icon: (
            <Icon
              color="currentColor"
              name="favorite"
              // @ts-expect-error - This is a known deviation from the design system.
              size={20}
            />
          ),
          isMobileOnly: true,
          label: 'Your homes',
          type: 'item',
        },
        {
          isMobileOnly: true,
          type: 'divider',
        },
      );
    }

    items.push(
      {
        analyticsName: `${analyticsPrefix}-contact-phone`,
        href: `tel:${telephone}`,
        label: `Call: ${telephone}`,
        type: 'item',
      },
      {
        analyticsName: `cosmos-${type}-header-${isExclusives ? 'sell' : 'buy'}`,
        href: isExclusives ? '/exclusives/offers' : '/exclusives',
        label: `${isExclusives ? 'Sell' : 'Buy'} with Exclusives`,
        type: 'item',
      },
      {
        analyticsName: `${analyticsPrefix}-FAQs`,
        href: '/exclusives/faq',
        label: 'FAQs',
        type: 'item',
      },
    );

    if (isLoggedIn) {
      items.push(
        {
          type: 'divider',
        },
        {
          analyticsName: `${analyticsPrefix}-account`,
          href: '/account',
          label: 'Account',
          isMobileOnly: true,
          type: 'item',
        },
      );
    }

    return items;
  }, [isLoggedIn, onLogOut, router, type, leads]);

  /**
   * This code takes the position of the trigger element and
   * offsets the menu so that it is positioned next to the trigger.
   */
  const handleMenuPosition = useCallback(() => {
    /**
     * We offset the menu X position by `16px` to ensure that the close
     * button inside the menu is aligned with the trigger elemenet.
     */
    const menuXOffset = 16;

    /**
     * We offset the menu Y position by `16px` to ensure that the close
     * button inside the menu is aligned with the trigger elemenet.
     */
    const menuYOffset = 16;

    if (triggerRef.current && menuRef.current) {
      const triggerRect = triggerRef.current?.getBoundingClientRect();
      const menuRect = menuRef.current?.getBoundingClientRect();

      const positionX = triggerRect.left - menuRect.width + triggerRect.width + menuXOffset;
      const positionY = triggerRect.top - menuYOffset;

      /**
       * We use `translate3d` over `top` and `left` because it is
       * more performant.
       */
      menuRef.current.style.transform = `translate3d(${positionX}px, ${positionY}px, 0)`;
    }
  }, [isOpened, menuRef, triggerRef]);

  /**
   * This effect is responsible for positioning the menu when it is opened
   * and when the window is resized.
   */
  useEffect(() => {
    if (isOpened) {
      handleMenuPosition();

      window.addEventListener('resize', handleMenuPosition);
    }

    return () => {
      window.removeEventListener('resize', handleMenuPosition);
    };
  }, [handleMenuPosition]);

  return (
    <Flex display={['flex', null, null, isLoggedIn ? 'flex' : 'none']}>
      <Button
        analyticsName={`${analyticsPrefix}-open`}
        aria-label="Open header menu"
        backgroundColor="transparent"
        border="none"
        boxShadow="none"
        onClick={() => setIsOpened(true)}
        ref={triggerRef}
        variant="icon"
      >
        <Icon
          name="menu"
          // @ts-expect-error - This is a known deviation from the design system.
          size={20}
        />
      </Button>

      {isOpened &&
        createPortal(
          <Flex
            alignItems="flex-end"
            backgroundColor="neutrals0"
            boxShadow="z1"
            flexDirection="column"
            // @ts-expect-error - This is a known deviation from the design system.
            borderBottomRadius={10}
            inset="0 auto auto 0"
            justifyItems="flex-end"
            maxHeight="var(--doc-height)"
            minWidth={240}
            overflowY="auto"
            position="fixed"
            py={5}
            px={5}
            ref={menuRef}
            textAlign="right"
            transformOrigin="top right"
            zIndex={10}
          >
            <Flex
              backgroundColor="neutrals0"
              justifyContent="flex-end"
              mt={-5}
              position="sticky"
              pt={5}
              top={-5}
              width="100%"
            >
              <Button
                analyticsName={`${analyticsPrefix}-close`}
                aria-label="Close header menu"
                backgroundColor="transparent"
                border="none"
                boxShadow="none"
                onClick={() => setIsOpened(false)}
                variant="icon"
              >
                <Icon
                  name="close"
                  // @ts-expect-error - This is a known deviation from the design system.
                  size={20}
                />
              </Button>
            </Flex>

            <List mt={3} variant="text">
              {menuItems.map((item, index) => {
                if (item.type === 'divider') {
                  return (
                    <Divider
                      display={item.isMobileOnly ? ['block', null, null, 'none'] : 'block'}
                      key={`header-menu-divider-${index}`}
                      my={4}
                    />
                  );
                }

                if (item.type === 'button') {
                  return (
                    <HeaderMenuItem
                      analyticsName={item.analyticsName}
                      as="button"
                      href=""
                      icon={item.icon}
                      onClick={item.onClick}
                      isMobileOnly={item.isMobileOnly}
                      label={item.label}
                      key={`header-menu-item-${index}`}
                    />
                  );
                }

                if (item.type === 'item') {
                  return (
                    <HeaderMenuItem
                      analyticsName={item.analyticsName}
                      href={item.href}
                      icon={item.icon}
                      label={item.label}
                      key={`header-menu-item-${index}`}
                      isMobileOnly={item.isMobileOnly}
                    />
                  );
                }

                return null;
              })}

              {isLoggedIn && (
                <ListItem>
                  <HeaderNavigationItem
                    as="button"
                    analyticsName={`${analyticsPrefix}-logout`}
                    aria-label="Go to Sign out"
                    href=""
                    onClick={() => {
                      trackEvent('cta-click', 'account-signout', undefined, {
                        categoryOverride: 'homepage',
                      });

                      onLogOut();
                      return window.location.assign('/exclusives');
                    }}
                    width="100%"
                  >
                    Sign out
                  </HeaderNavigationItem>
                </ListItem>
              )}
            </List>
          </Flex>,
          document.body,
        )}
    </Flex>
  );
};

export default LoggedInConnector(HeaderMenu);
